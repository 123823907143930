import React from "react";
import { Link } from "gatsby";
import { IoMdOpen, IoIosArrowForward } from "react-icons/io";
import {
  IoFlash,
  IoDocuments,
  IoStatsChart,
  IoCheckmarkCircle,
  IoSwapHorizontal,
  IoCalendar,
  IoShieldCheckmark,
} from "react-icons/io5";

import Image from "../components/image";
import {
  Container,
  Wrapper,
  Row,
  Box,
  Button,
  FlexButtons,
} from "../components/util";
import Layout from "../components/layout";
import Seo from "../components/seo";
import {
  IframeContainer,
  CallToAction,
  ImageGraphic,
  Breadcrumb,
} from "../components/site";

import PayHeroPlusInvoxy from "../images/logos/PayHero/PayHeroPlusInvoxy.svg";
import PayHeroLogo from "../images/logos/PayHero/PayHero_Full.svg";
import InvoxyLogo from "../images/logos/Invoxy/Invoxy_Full.svg";

const Karmly = (props) => (
  <Layout>
    <Seo
      title="Karmly + PayHero Integration | Invoicing & Payroll"
      description="Connect PayHero and Karmly to accurately bill your clients and pay your candidates from the moment you make a placement."
      pathname={props.location.pathname}
    />
    <Breadcrumb
      parentPage="Integrations"
      parentPageLink="/add-ons"
      currentPage="Karmly"
    />
    <Container>
      <Wrapper stackGap={80}>
        <Row stackGap={60} alignCenter>
          <Box size={50} stackGap={30} centerOnMobile>
            <img
              src={PayHeroPlusInvoxy}
              alt="PayHero + Karmly Integration"
              height="40"
            />
            <Box stackGap={10}>
              <h1 className="-fontLarge">
                <span className="-fontPrimary">Deeply integrated</span>
                <br className="hideOnMobile" /> payroll and invoicing
              </h1>
              <h4>
                Connect PayHero and Karmly to accurately bill your clients and
                pay your candidates from the moment you make a placement.
              </h4>
            </Box>
            <FlexButtons>
              <Button to="/signup" className="primary -lg gtm-cta">
                Get Started with PayHero
              </Button>
              <Button
                to="https://www.karmly.com/recruitment"
                className="grey -lg"
                atag
                externalLink
              >
                Discover Karmly{" "}
                <IoMdOpen css={{ top: "2px", marginLeft: "5px" }} />
              </Button>
            </FlexButtons>
          </Box>
          <Box size={50} css={{ padding: "40px" }}>
            <ImageGraphic variant="1" />
            <Image
              filename="PageHeader_Invoxy.png"
              alt="Karmly Billing | PayHero Integration"
              rounded
              addShadow
            />
          </Box>
        </Row>
      </Wrapper>
    </Container>
    <Container className="-textCenter">
      <Wrapper css={{ paddingTop: "0" }}>
        <hr />
        <div>
          <h2>Streamlined billing and payment processing</h2>
          <h4>
            Single sign-on two-way payroll integration for New Zealand
            recruitment agencies.
          </h4>
        </div>
        <Row stackGap={40}>
          <Box size={33}>
            <h3>
              <IoFlash />
              <br />
              Accurate, fast payroll
            </h3>
            <p>
              Generate 100% compliant NZ payroll for temps, contractors and your
              own staff. Give your candidates access to add time & expenses,
              request leave and view payslips and BCI’s.
            </p>
          </Box>
          <Box size={33}>
            <h3>
              <IoDocuments />
              <br />
              Buyer created invoices
            </h3>
            <p>
              Contractors can download their own buyer created invoices from
              Karmly, greatly simplifying their payment process.
            </p>
          </Box>
          <Box size={33}>
            <h3>
              <IoStatsChart />
              <br />
              Powerful reporting
            </h3>
            <p>
              Make decisions based on the metrics and KPIs that drive your
              business. Karmly’s advanced business intelligence platform
              delivers Net Direct Revenue and other custom reports.
            </p>
          </Box>
        </Row>
        <Image
          filename="InvoxyToPayHero_Integration.png"
          alt="Karmly Integration | PayHero"
          addShadow
        />
        <Box className="-center" stackGap={60} css={{ maxWidth: "1000px" }}>
          <Row stackGap={40}>
            <Box size={50}>
              <h3>
                <IoCheckmarkCircle />
                <br />
                Your complete back office
              </h3>
              <p>
                Everything you need to accurately invoice your clients and pay
                your candidates from the moment you make a placement.
              </p>
            </Box>
            <Box size={50}>
              <h3>
                <IoSwapHorizontal />
                <br />
                Single sign on access
              </h3>
              <p>
                Administrators can easily access and switch between systems
                without having to sign in again.
              </p>
            </Box>
          </Row>
          <Row stackGap={40}>
            <Box size={50}>
              <h3>
                <IoCalendar />
                <br />
                Integrated leave management
              </h3>
              <p>
                Temps and other non-contractors can view leave balances and
                request leave from inside Karmly.
              </p>
            </Box>
            <Box size={50}>
              <h3>
                <IoShieldCheckmark />
                <br />
                Take your compliance seriously
              </h3>
              <p>
                PayHero stores annual leave in weeks and reviews recent work
                patterns to ensure you’re fully compliant with the New Zealand
                Holidays Act.
              </p>
            </Box>
          </Row>
        </Box>
      </Wrapper>
    </Container>
    {/* <Container bg="lightGrey">
      <Wrapper className="-textCenter">
        <Box stackGap={60}>
          <div>
            <h2>How the integration works</h2>
            <h4>
              Payroll in Karmly just got a whole lot easier for New Zealand
              recruitment agencies.
            </h4>
          </div>
          <div className="-center -addShadow" css={{ maxWidth: "1000px" }}>
            <IframeContainer
              src="https://player.vimeo.com/video/362163746"
              title="PayHero + Karmly Integration | Video"
            />
          </div>
          <a
            href="https://support.karmly.com/hc/en-us/articles/360002636356-PayHero-Integration"
            target="_blank"
            rel="noopener noreferrer"
            className="link-floating -center"
          >
            See Support Article <IoMdOpen css={{ top: "2px", left: "3px" }} />
          </a>
        </Box>
      </Wrapper>
    </Container> */}
    <Container>
      <Wrapper>
        <Row stackGap={60} alignCenter>
          <Box size={50}>
            <img
              className="-center"
              src={PayHeroLogo}
              alt="PayHero | Online Payroll Made Super Easy"
              height="80"
            />
          </Box>
          <Box size={50} stackGap={30}>
            <div>
              <h2>About PayHero</h2>
              <h4>
                PayHero is perfect for Kiwi recruitment companies that want
                stress-free and compliant online payroll. This{" "}
                <Link to="/payroll">payroll software</Link> is designed for
                flexible workforces facing unique challenges including tracking
                employee time and calculating the right holiday entitlements.
              </h4>
            </div>
            <ul>
              <li>
                Automatically calculate pay and holiday entitlements from online
                timesheets and work patterns.
              </li>
              <li>
                Connect to payday filing and we’ll send your PAYE information to
                IRD.
              </li>
              <li>
                All leave is handled in line with the Holidays Act and latest
                government guidance
              </li>
              <li>
                Automatically send payroll transactions to Xero and track wage
                costs with account codes or tracking categories.
              </li>
              <li>Expert phone and email support from our NZ-based team.</li>
            </ul>
            <Link className="link-arrow" to="/signup">
              Get Started with PayHero <IoIosArrowForward />
            </Link>
          </Box>
        </Row>
        <hr />
        <Row stackGap={60} alignCenter mobileReverse>
          <Box size={50} stackGap={30}>
            <div>
              <h2>About Karmly</h2>
              <h4>
                Karmly is New Zealand’s favourite middle and back-office
                software for recruitment agencies. Improve the client and
                candidate experience with professional, easy to use software
                that showcases your brand.
              </h4>
            </div>
            <ul>
              <li>
                Get client approval of timesheets online and attach the details
                to your invoices for faster payment
              </li>
              <li>
                Bulk messaging simplifies and speeds up the process of chasing
                up timesheets and client approvals
              </li>
              <li>
                Load placement data automatically with our Bullhorn and JobAdder
                integrations
              </li>
              <li>
                Automatically calculate complex commissions and track sales
                performance
              </li>
              <li>
                Get candidates to enter information and complete onboarding
                tasks when they start a new role
              </li>
            </ul>
            <p>
              Find out more about{" "}
              <a href="https://www.karmly.com/recruitment" target="_blank">
                Karmly
              </a>
              .
            </p>
          </Box>
          <Box size={50}>
            <img
              className="-center"
              src={InvoxyLogo}
              alt="Karmly Integration | PayHero"
              height="80"
            />
          </Box>
        </Row>
      </Wrapper>
    </Container>
    <CallToAction
      title="Connect PayHero & Karmly today"
      description="Accurately bill your clients and pay your candidates from the moment you make a placement."
      buttonText="Get Started with PayHero"
    />
  </Layout>
);

export default Karmly;
